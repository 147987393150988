import { DevExpertiseCompanyMock } from '~/types/mock'

const MultiVendorDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '50+',
    text: 'successfully delivered software projects',
  },
  {
    title: '9+',
    text: 'years as a credible IT development services provider',
  },
  {
    title: '60+',
    text: 'mature experts on board',
  },
  {
    title: '$56+',
    text: 'mln obtained by our clients',
  },
]

export default MultiVendorDevExpertise
