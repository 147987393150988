import { FAQ } from '~/types/data-array'

const MultiVendorFAQ: FAQ[] = [
  {
    question: 'What is a multi-vendor marketplace?',
    answer:
      'A multi-vendor marketplace platform is an e-solution that enables numerous vendors to make their online stores and sell various products.',
  },
  {
    question: 'How long does it take to build a multi-vendor marketplace?',
    answer:
      'Creating a multi-vendor platform will take you about 7 months. This time is relevant if you choose the custom marketplace development.',
  },
  {
    question: 'How much does it cost to develop a multi-vendor marketplace?',
    answer:
      'In Eastern Europe, building a multi-vendor website costs about $50,000-$120,000. However, you need to consider that the total price will change depending on your multi vendor marketplace developer rates.',
  },
]

export default MultiVendorFAQ
