import { ExpertiseSectionMock } from '~/types/mock'

const MultiVendorFeatures: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_28',
    title: 'Easy store management',
    description:
      'Monitor your inventory state, orders, sales, and deliveries with the store management system.',
  },
  {
    icon: 'spriteIcon_12',
    title: 'Convenient product listings',
    description:
      'The convenience of listings influences consumers’ decisions to buy your product or service.',
  },
  {
    icon: 'spriteIcon_26',
    title: 'Stock management',
    description:
      'Stock management software helps organize services or products for efficient business growth.',
  },
  {
    icon: 'spriteIcon_95',
    title: 'Advanced dashboard',
    description:
      'With an advanced dashboard, vendors get a powerful feature set to trade confidently.',
  },
  {
    icon: 'spriteIcon_29',
    title: 'Easy order system',
    description:
      'An easy order system maximizes the pros for you and your marketplace users.',
  },
  {
    icon: 'spriteIcon_47',
    title: 'Reviews & ratings',
    description:
      'Considering ratings and reviews, users can find the most suitable products and services.',
  },
  {
    icon: 'spriteIcon_96',
    title: 'Delivery tracking',
    description:
      'This feature helps vendors and shoppers view the delivery status of their orders conveniently.',
  },
  {
    icon: 'spriteIcon_97',
    title: 'Payment system',
    description:
      'At Codica, we implement the most secure payment systems for safe financial transactions.',
  },
  {
    icon: 'spriteIcon_94',
    title: 'Customizable fees',
    description:
      'You will manage your multi-vendor marketplace fees taken on each sale made on your site.',
  },
  {
    icon: 'spriteIcon_98',
    title: 'Messaging & notifications',
    description:
      'Thanks to the convenient messaging and notification system, you will improve user experience.',
  },
  {
    icon: 'spriteIcon_3',
    title: '3rd party integrations',
    description:
      'With 3d party integrations, you get additional capabilities for your multi-vendor marketplace.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Custom features',
    description:
      'Custom solutions we build are unique and scalable - ideal for complex projects.',
  },
]

export default MultiVendorFeatures
