import { ExpertiseSectionMock } from '~/types/mock'

const MultiVendorBusiness: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'The rise of multi-vendor marketplace startups is impressive. At Codica, we will help you develop and launch your solution quickly and with wise expenses. So, you will catch the eCommerce wave and get measurable results.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small businesses',
    description:
      'Creating a multi-vendor marketplace for small businesses helps strengthen your brand and provides greater control over it. This assists in building direct customer relationships and influences your business’s further growth.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Mid-scale businesses',
    description:
      'Codica’s team of skilled specialists will develop a highly-responsive and attractive multi-vendor marketplace. For this, we use our proven tech stack and the best industry standards. Also, we are always ready to support your solution after launch and help to scale',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'Enterprises',
    description:
      'If you have a multi-vendor marketplace, we are on hand to help your business develop and convert more leads into purchasers. With our services, you will attract a more significant number of users and drive sales. If you aim to be among market leaders, turn to us!',
  },
]

export default MultiVendorBusiness
