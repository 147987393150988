import { RequirementsSoftwareMock } from '~/types/mock'

const MultiVendorIdeaToLife: RequirementsSoftwareMock[] = [
  {
    icon: 'spriteIcon_22',
    title: 'Experienced project managers',
    description:
      'They control the agile web development process from start to release.',
  },
  {
    icon: 'spriteIcon_23',
    title: 'Creative UX/UI specialists',
    description:
      'Make the user interface and guarantee a great user experience.',
  },
  {
    icon: 'spriteIcon_43',
    title: 'Mature technical leads',
    description: 'Set the tech development vector and monitor its rightness.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'Skilled developers',
    description:
      'Bring visions into a code, following the prescribed tech requirements.',
  },
  {
    icon: 'spriteIcon_99',
    title: 'Qualified DevOps engineers',
    description:
      'Automate tasks related to configuring and deploying software apps.',
  },
  {
    icon: 'spriteIcon_91',
    title: 'Expert QA specialists',
    description:
      'Improve the web development processes and detect errors in the app.',
  },
]

export default MultiVendorIdeaToLife
