import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const MultiVendorProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Research and discovery',
    descr: (
      <>
        During the
        <Link
          to="/services/product-discovery/"
          className="mx5"
          key="keyProductDiscovery"
        >
          product discovery
        </Link>
        phase, we plan the creation of your multi-vendor marketplace. We
        identify the target market, competitors, and product features.
      </>
    ),
  },
  {
    num: '02',
    title: 'Design',
    descr:
      'Our designers create your marketplace’s interfaces. We design wireframes and prototypes to test the user journey to make an intuitive and convenient site.',
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'At this stage, the coding takes place. Codica’s mature experts select the best tech stack for your requirements and use the latest industry standards.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr:
      'We thoroughly test the multi-vendor marketplaces. This enables us to ensure seamless work and enhanced experience for your early users.',
  },
  {
    num: '05',
    title: 'Deployment and support',
    descr:
      'Our engineers monitor your multi-vendor solution and solve any issues that may arise. We are glad to take care of your site and support it if needed.',
  },
]

export default MultiVendorProcess
