import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useMultiVendorMarketplaceStaticQuery from '~/views/Services/MultiVendorMarketplace/useMultiVendorMarketplaceStaticQuery'

const photoDefault = '../../../assets/images/avatars/AvatarDraft.png'
const photoClientTradervs =
  '../../../assets/images/avatars/clients/client-tradervs.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const MultiVendorTabContent = (): WorksTabsContent[] => {
  const query = useMultiVendorMarketplaceStaticQuery()

  const portfolioBoat = getImage(fileToImageLikeData(query.portfolioBoat))
  const portfolioMalleni = getImage(fileToImageLikeData(query.portfolioMalleni))

  const tabContent = [
    {
      key: 'Boat-selling marketplace',
      gradient: 'gradientTradeaboat',
      image: portfolioBoat,
      header: 'Online boat-selling marketplace',
      title:
        'This is a Trade A Boat, an Australian-based online platform to sell and buy new or used boats and marine equipment.',
      resultsLeftTitle: '480%',
      resultsLeft: 'increase in lead generation performance',
      resultsRightTitle: 'null',
      resultsRight: 'Robust, intuitive, and fast-loading platform',
      link: 'online-marketplace-for-boats',
      review:
        '“We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year. They are like my secret weapon.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientTradervs}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Celso Prado',
      clientPosition: 'Digital General Manager at the Adventures Group',
    },
    {
      key: 'Ecommerce solution',
      gradient: 'gradientMalleni',
      image: portfolioMalleni,
      header: 'Custom Ecommerce solution',
      title:
        'This is the first online marketplace in the UK and Europe that enables users to shop and sell their services and products in one place.',
      resultsLeft: 'Complex marketplace with B2C and C2C environments',
      resultsRight: 'Enhanced design for all web solution parts',
      link: 'custom-multi-vendor-marketplace-platform',
      review:
        '“The Codica company is a team of professionals. Together we created a unique solution for the market in Europe and the United Kingdom that allows buying and selling products and providing services in one place.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoDefault}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'CEO',
      clientPosition: 'Ecommerce Marketplace Company, United Kingdom',
    },
  ]

  return tabContent
}

export default MultiVendorTabContent
