import { ExpertiseSectionMock } from '~/types/mock'

const MultiVendorBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_27',
    title: 'User-friendly',
    description:
      'At Codica, we build multi-vendor marketplaces that are easy to navigate, attractive, and fully responsive. With them, you can provide your customers with content based on their interests.',
  },
  {
    icon: 'spriteIcon_37',
    title: 'Secure',
    description:
      'Our experts care about building safe multi-vendor marketplaces that meet all security and compliance objectives. Users will enjoy using them to sell or buy goods and services.',
  },
  {
    icon: 'spriteIcon_101',
    title: 'Fast-loading',
    description:
      'The high loading speed and overall performance of multi-vendor marketplaces are crucial for a positive user experience. This also influences search engine rankings.',
  },
  {
    icon: 'spriteIcon_44',
    title: 'Scalable',
    description:
      'Codica’s specialists can develop a powerful and scalable multi-vendor marketplace. We will do it in a cost-effective way and ensure to maximize your profit returns.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'SEO-friendly',
    description:
      'Our skilled experts will optimize your multi-vendor marketplace for search engines and target keywords. Thus, we provide the best SEO ranking for your solution.',
  },
  {
    icon: 'spriteIcon_14',
    title: 'Engageable',
    description:
      'At Codica, our experienced designers will create the perfect marketplace design. With an intuitive UX design and a beautiful UI layout, your business will engage more users.',
  },
]

export default MultiVendorBenefits
