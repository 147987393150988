import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/multi-vendor-marketplace-development/Anchors'
import MultiVendorBenefits from '~/mock/services/multi-vendor-marketplace-development/MultiVendorBenefits'
import MultiVendorBusiness from '~/mock/services/multi-vendor-marketplace-development/MultiVendorBusiness'
import MultiVendorDevExpertise from '~/mock/services/multi-vendor-marketplace-development/MultiVendorDevExpertise'
import MultiVendorFAQ from '~/mock/services/multi-vendor-marketplace-development/MultiVendorFAQ'
import MultiVendorFeatures from '~/mock/services/multi-vendor-marketplace-development/MultiVendorFeatures'
import MultiVendorIdeaToLife from '~/mock/services/multi-vendor-marketplace-development/MultiVendorIdeaToLife'
import MultiVendorIndustries from '~/mock/services/multi-vendor-marketplace-development/MultiVendorIndustries'
import MultiVendorProcess from '~/mock/services/multi-vendor-marketplace-development/MultiVendorProcess'
import MultiVendorServices from '~/mock/services/multi-vendor-marketplace-development/MultiVendorServices'
import MultiVendorTabContent from '~/mock/services/multi-vendor-marketplace-development/MultiVendorTabContent'
import MultiVendorTabList from '~/mock/services/multi-vendor-marketplace-development/MultiVendorTabList'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useMultiVendorMarketplaceStaticQuery from '~/views/Services/MultiVendorMarketplace/useMultiVendorMarketplaceStaticQuery'

import * as containerStyles from './MultiVendorMarketplace.module.scss'

const MultiVendorMarketplace = () => {
  const query = useMultiVendorMarketplaceStaticQuery()
  const multiVendorTabContent = MultiVendorTabContent()
  const clientRef = getImage(fileToImageLikeData(query.clientRef))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Multi-Vendor"
        title="Marketplace Development"
        description="Aim to be a winner in sales? We will help you to create a profitable multi-vendor marketplace with unlimited development opportunities."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Multi-Vendor Marketplace Development"
        breadcrumbCurrentPath="/services/multi-vendor-marketplace-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.multiVendorServicesSection}
      >
        <HeadSection
          position="center"
          title="Multi-vendor marketplace development services"
          description="Creating a multi-vendor solution is quite a complex task. That’s the reason why you should rely on software development professionals with strong expertise to accomplish it. With Codica’s quality multi vendor marketplace development services, you receive top-notch marketplace software."
          descriptionLarge
        />
        <Service3DIconCard dataContent={MultiVendorServices} />
      </section>

      <section
        id="features"
        className={containerStyles.multiVendorFeaturesSection}
      >
        <HeadSection
          position="center"
          title="Get any of the features in your multi-vendor marketplace"
          description="At Codica, we are an expert team of software developers and know how to build solutions that people like. The following are the core features that are must-haves for a multi-vendor store to engage more users."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={MultiVendorFeatures} />
      </section>

      <CallToAction
        title="Have a vision for a multi-vendor platform?"
        subtitle="Let’s transform it into reality!"
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.multiVendorBusinessesSection}
      >
        <HeadSection
          position="center"
          title="Business types we serve"
          description="Business size is about the scale of the enterprise's operations. At Codica, we have vast experience creating multi-vendor marketplaces for businesses of any size. So, we know how to close the issues of each business type."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={MultiVendorBusiness} />
      </section>

      <section
        className={containerStyles.multiVendorIndustriesSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience developing software for specific fields has enabled us to grow our in-house expertise, which we are open to sharing with our clients. We offer consultations on every phase of software development and make reliable and modern products."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MultiVendorIndustries} />
      </section>

      <section
        id="process"
        className={containerStyles.multiVendorProcessSection}
      >
        <HeadSection
          position="center"
          title="Multi-vendor marketplace development process"
          description="Choosing the custom multi-vendor eCommerce marketplace development lets you control your platform's functions. Also, you can add many tailored features. Our proven tech expertise and efficient development process enable us to build modern, scalable marketplaces that can launch fast."
          descriptionLarge
        />
        <ServicesProcess dataContent={MultiVendorProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.multiVendorTechStackSection}
      >
        <HeadSection
          position="center"
          title="Best tech stack for creating a multi-vendor marketplace"
          description={
            <>
              The right tech stack for
              <Link
                to="/services/online-marketplace-development/"
                className="mx5"
                key="keyLinkDescription"
              >
                online marketplace development
              </Link>
              will help you keep pace with modern trends. Also, it will allow
              you to provide the best service to your customers. At Codica, we
              will help you to choose the tech stack that ensures you have a
              secure, easy-to-use, and stable solution and achieve business
              success.
            </>
          }
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section className={containerStyles.multiVendorIdeaToLifeSection}>
        <HeadSection
          position="center"
          title="Team that will bring your idea to life"
          description="With Codica, you get an expert team on board who will do their best for your business."
          titleLarge
        />
        <ServicesIconSmallCard dataContent={MultiVendorIdeaToLife} />
        <SubtractBlock />
      </section>

      <section className={containerStyles.multiVendorDiscoverWorksSection}>
        <HeadSection position="left" title="Featured case studies" />
        <DiscoverWorksTabs
          dataTabList={MultiVendorTabList}
          dataContent={multiVendorTabContent}
        />
      </section>

      <section
        id="benefits"
        className={containerStyles.multiVendorBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Benefits of the multi-vendor marketplace development with Codica"
          description="A multivendor marketplace development requires superior technology skills and an experienced development team to begin the business at the right chord. Codica is the team you need for the best results."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={MultiVendorBenefits} />
      </section>

      <CallToAction
        title="Looking for a reliable multi-vendor development team?"
        subtitle="Our specialists will deliver your business project."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.multiVendorDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Why is it worth choosing Codica?"
        />
        <ServicesDevExpertiseCompany dataContent={MultiVendorDevExpertise} />
      </section>

      <section
        id="cases"
        className={containerStyles.multiVendorSuccessStoriesSection}
      >
        <HeadSection
          position="center"
          title="Case studies"
          description="The Codica team has established itself as a top multi vendor marketplace development company. And our successfully completed software projects are proof of it."
          descriptionLarge
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.multiVendorOurAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are fans of cutting-edge technologies. Codica is recognizable by many independent ratings and research organizations."
          descriptionLarge
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.multiVendorClinetsSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s create a great multi-vendor marketplace together"
          widthTitle="800px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our multi-vendor marketplace development company’s experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MultiVendorFAQ} />
      </section>
    </MainLayout>
  )
}

export default MultiVendorMarketplace
