// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "C_fr";
export var multiVendorBusinessesSection = "C_fk";
export var multiVendorClinetsSection = "C_fw";
export var multiVendorDevExpertiseSection = "C_fs";
export var multiVendorDiscoverWorksSection = "C_fq";
export var multiVendorFeaturesSection = "C_fj";
export var multiVendorIdeaToLifeSection = "C_fp";
export var multiVendorIndustriesSection = "C_fl";
export var multiVendorOurAchievementsSection = "C_fv";
export var multiVendorProcessSection = "C_fm";
export var multiVendorServicesSection = "C_fh";
export var multiVendorSuccessStoriesSection = "C_ft";
export var multiVendorTechStackSection = "C_fn";