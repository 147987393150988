import { DataTabList } from '~/types/data-array'

const MultiVendorTabList: DataTabList[] = [
  {
    name: 'Boat-selling marketplace',
  },
  {
    name: 'Ecommerce solution',
  },
]

export default MultiVendorTabList
