import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useMultiVendorMarketplaceStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      portfolioBoat: file(
        relativePath: { eq: "homepage/discover-works-portfolio-9.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioMalleni: file(
        relativePath: { eq: "homepage/discover-works-portfolio-6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientRef: file(
        relativePath: { eq: "avatars/clients/large-photo/client-ref.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useMultiVendorMarketplaceStaticQuery
