import { Link } from 'gatsby'

import { ExpertiseSectionMock } from '~/types/mock'

const MultiVendorServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Consulting',
    description:
      'You can turn to our consulting services to obtain instructions on refining your multi-vendor marketplace for more flexibility, reliability, and performance.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Online marketplace design',
    description: (
      <>
        Our mature
        <Link to="/services/ui-ux-design/" className="mx5" key="keyDesign">
          UI/UX designers
        </Link>
        will develop an easy-to-use, comprehensive, and appealing multi-vendor
        marketplace according to your requirements.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_16',
    title: 'Product discovery',
    link: '/services/product-discovery/',
    description:
      'Codica’s professionals will conduct a complete analysis of all aspects of the project implementation in order to test the idea and protect the client from unplanned costs.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'Multi-vendor marketplace app development',
    description: (
      <>
        With our best
        <Link
          to="/services/mobile-app-development/"
          className="ml5"
          key="keyMobileApp"
        >
          mobile app development services
        </Link>
        , you will get an effective and fast-loading multi-vendor marketplace
        app that your users will adore.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Development of the multi-vendor marketplace',
    description:
      'By hiring our multi-vendor marketplace developers, you can be sure your multi-vendor marketplace will be fully functional, highly secure, and unique.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Quality assurance testing services',
    link: '/services/quality-assurance/',
    description:
      'Receive a fully custom and thoroughly tested multi-vendor marketplace website with low risks, wise costs, and no stress.',
  },
]

export default MultiVendorServices
